import React from 'react';
import ParallaxMousemove from "react-parallax-mousemove";
import logo_upshow_white from "./logo_white.svg";

function SecureLink404(props) {
    const style = {
        config: {
            xFactor: 0.1,
            yFactor: 0.1,
            springSettings: {
                stiffness: 50,
                damping: 30
            }
        },
        outer: {
            background: 'radial-gradient(50% 150%, #6CD7E8 50%, #59C2D3 100%)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '100%',
            position: 'relative',
            overflow: 'hidden'
        },
        Oval1: {
            position: 'relative',
            width: 360,
            height: 360,
            backgroundColor: '#319acc',
            borderRadius: '100%',
            transform: 'translate(-15%, 17%)',
            marginTop: '-36%',
            marginLeft: '-48%',
        },
        Oval2: {
            position: 'relative',
            width: 195,
            height: 195,
            backgroundColor: '#319acc',
            borderRadius: '100%',
            transform: 'translate(-15%, 17%)',
            marginTop: '18%',
            marginLeft: '21%',
        },
        Oval3: {
            position: 'relative',
            width: 830,
            height: 830,
            backgroundColor: '#319acc',
            borderRadius: '100%',
            transform: 'translate(-15%, 17%)',
            marginTop: '27%',
            marginLeft: '-45%',
        },
        Oval4: {
            position: 'relative',
            width: 457,
            height: 457,
            backgroundColor: '#319acc',
            borderRadius: '100%',
            transform: 'translate(-15%, 17%)',
            marginTop: -280,
            marginLeft: 272,
        },
        Oval5: {
            position: 'relative',
            width: 195,
            height: 195,
            backgroundColor: '#319acc',
            borderRadius: '100%',
            transform: 'translate(-15%, 17%)',
            marginTop: 290,
        },
        PageError: {
            height: '100%',
            width: '33%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    };

    if (props.status === 404) {
        let message = props.secure_link.message;
        console.error("The Secure token " + message);
    }
    document.body.style = 'background: #3aace2;';

    return (<div className="Page">
            <ParallaxMousemove containerStyle={style.PageError}>
                <ParallaxMousemove.Layer layerStyle={style.Oval1} config={style.config} />
                <ParallaxMousemove.Layer layerStyle={style.Oval2} config={style.config} />
                <ParallaxMousemove.Layer layerStyle={style.Oval3} config={style.config} />


            </ParallaxMousemove>
            <div className="PageError">
                <div className="Title">
                    <div className="RectangleTitle" />
                    <span className="titleError">Oops!</span>
                </div>
                <span className="subtitleError">Much like the dinosaur, this webview no longer exists. Please
                    double check the URL and your expiration settings.</span>
                <img className="ImgLogo" src={logo_upshow_white} alt="Logo"/>
            </div>
            <ParallaxMousemove containerStyle={style.PageError}>
                <ParallaxMousemove.Layer layerStyle={style.Oval4} config={style.config} />
                <ParallaxMousemove.Layer layerStyle={style.Oval5} config={style.config} />
            </ParallaxMousemove>
        </div>);
}

export default SecureLink404;