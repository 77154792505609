import React, { Component } from 'react';
import './App.css';
import * as localforage from 'localforage';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import logo_upshow from './logo_upshow.svg';
import moment from 'moment';
import SecureLink404 from './SecureLink404';

class App extends Component {
    key = 'upshow-serial-number';

    serialNumber = null;
    timeOut = null;
    pollingTime = 120000;
    secure_link = {
        secure_link: null,
        api_token: null,
        simulated_date: null,
        expiration: null,
        status: null,
        message: null,
    };
    hwInfo = {
        model: 'web-loader'
    };

    constructor (props) {
        super(props);

        this.state = {
            url: 'about:blank',
            secure_link_not_found: false
        };
    }

    async componentDidMount () {
        const url = new URL(document.location.toString());

        if (url.searchParams.has('bust-serial')) {
            url.searchParams.delete('bust-serial');

            await localforage.removeItem(this.key).then(() => {
                window.history.pushState({}, 'UPshow', url);
            });
        }

        if (url.searchParams.has('secure_link')) {
            this.secure_link.secure_link = url.searchParams.get('secure_link');
        }

        const serialType = await this.loadSerial();

        if (serialType === 'secure-link') {
            //for secure links we don't store the token as it is regenerated everytime
            //also we need to have the server generate our api key
            await this.loadPrivateToken();
        } else if (this.serialNumber === null) {
            this.serialNumber = uuidv4();

            //we don't care when or if this runs, hence, we don't need to await
            localforage.setItem(this.key, this.serialNumber)
                .then((value) => console.log(`Successfully stored serial: ${value}`))
                .catch((err) => console.error(err));
        }

        return this.pollSettings();
    }

    componentWillUnmount () {
        clearTimeout(this.timeOut);
    }

    async loadPrivateToken () {
        try {
            const restUrl = `${process.env.REACT_APP_URL_REST}/secure_link/${this.secure_link.secure_link}?device_id=${this.serialNumber}`;
            const result = await axios.get(restUrl);

            this.secure_link.api_token = result.data.token;
            this.secure_link.status = result.status;
            this.secure_link.simulated_date = result.data.simulated_date;
            this.secure_link.expiration = result.data.expiration;
            this.secure_link.type = result.data.type;
        } catch (err) {
            if (err.response) {
                this.secure_link.status = err.response.status;
                this.secure_link.message = err.response.data?.message;
            }

            console.error(err);
        }

        if (this.secure_link.api_token === null) {
            this.setState({ secure_link_not_found: true });
        }
    }

    loadSerial () {
        const url = new URL(document.location);

        //If this is a secure link *always* generate a new serial number because webviews from secure links
        //are ephemeral
        if (this.secure_link.secure_link) {
            this.serialNumber = `secure-link-${uuidv4()}`;
            console.log(`Generated serial for secure link: ${this.serialNumber}`);

            return 'secure-link';
        } else if (!!url.searchParams.get('deviceId')) {
            this.serialNumber = url.searchParams.get('deviceId');

            return 'url';
        } else if (this.isBrightSign()) {
            if (!this.hwInfo.deviceId || !this.hwInfo.model) {
                throw Error('missing parameter in hwInfo');
            }

            this.serialNumber = this.hwInfo.deviceId;
            console.log(`Found Serial from HW: ${this.serialNumber}`);

            return 'hardware';
        } else {
            return localforage.getItem(this.key).then((value) => {
                // This code runs once the value has been loaded
                // from the offline store.
                this.serialNumber = value;
                console.log(`Found Serial: ${this.serialNumber}`);

                return 'storage';
            });
        }
    }

    isBrightSign () {
        const userAgent = navigator.userAgent;

        if (/^BrightSign/.test(userAgent)) {
            //we have a brightsign box
            const parts = userAgent.split('/');
            if (parts.length >= 3) {
                this.hwInfo = {
                    deviceId: parts[1],
                    model: 'BrightSign'
                };
            }

            return true;
        }

        return false;
    }

    async pollSettings () {
        try {
            const settings = await this.getHwSettings();

            const uiUrl = new URL(settings.ui_url);
            uiUrl.searchParams.set('deviceId', this.serialNumber);

            uiUrl.searchParams.set('disableServiceWorker', '1');

            if (window.location.hash) {
                uiUrl.hash = window.location.hash.substring(1);
            }

            if (!window.location.hash && settings.api_token) {
                uiUrl.searchParams.set('api_token', settings.api_token);
            }

            if (this.secure_link.simulated_date) {
                uiUrl.searchParams.set('ui.forced_iso_date', this.secure_link.simulated_date);
            }

            App.passThruGetParams(uiUrl);

            this.setState({
                url: uiUrl.toString()
            });

        } catch (e) {
            console.error(e);
        } finally {
            this.timeOut = setTimeout(() => this.pollSettings(), this.pollingTime);
        }
    }

    getHwSettings () {
        const hardwareContextUrl = process.env.REACT_APP_URL_APP_EVENTS + '/api/hw/settings';

        const url = new URL(hardwareContextUrl);

        url.searchParams.set('device_id', this.serialNumber);
        url.searchParams.set('model', this.hwInfo.model);

        return fetch(url).then(response => response.json());
    }

    static passThruGetParams (url) {
        if (document.location.toString().indexOf('?') !== -1) {
            const query = document.location
                .toString()
                // get the query string
                .replace(/^.*?\?/, '')
                // and remove any existing hash string (thanks, @vrijdenker)
                .replace(/#.*$/, '')
                .split('&');

            for (let i = 0, l = query.length; i < l; i++) {
                const aux = query[i].split('=');

                if (aux[0] !== undefined && aux[1] !== undefined) {
                    url.searchParams.set(aux[0], decodeURIComponent(aux[1]));
                }
            }
        }

        return url;
    }

    renderPreviewMessage (simulated_date, expiration_date) {
        if (this.secure_link.type === 'PREVIEW') {
            return 'Current Playlist Preview';
        } else {
            if (!!simulated_date) {
                return <>This secure link preview for <span className="LabelDays">{simulated_date}</span> will expire in <span className="LabelDays">{expiration_date}</span></>;
            } else {
                return <>This secure link preview will expire in <span className="LabelDays">{expiration_date}</span></>;
            }
        }
    }

    renderInfoSecureLink () {
        let expiration_date = null;
        if (!!this.secure_link.expiration) {
            const days = (new Date(this.secure_link.expiration) - new Date()) / 1000 / 60 / 60 / 24;
            const daysAbs = Math.round(days);
            expiration_date = `${daysAbs} days.`;
            if (days < 1) {
                const hours = days * 24;
                const hoursAbs = Math.round(hours);
                expiration_date = `${hoursAbs} hours.`;
                if (hours < 1) {
                    const minutes = Math.round(hours * 60);
                    expiration_date = `${minutes} minutes.`;
                }
            }
        }

        let simulated_date;
        if (!!this.secure_link.simulated_date) {
            simulated_date = new moment(this.secure_link.simulated_date).format('MM/DD/YYYY - hh:mm A');
        }

        return (<div className="InfoBox">
            <img src={logo_upshow} alt="Logo"/>
            <span className="Line"/>
            <span className="InfoMessage">
                {this.renderPreviewMessage(simulated_date, expiration_date)}
            </span>
        </div>);
    }

    render () {
        // If we have a secure_link but we don't have an api_token for it
        if (this.state.secure_link_not_found) {
            return (<SecureLink404 secure_link={this.secure_link}/>);
        }

        return (<div className={'wrapper'}>
            <iframe title="UPshow" src={this.state.url} height={'100%'} width={'100%'}/>
            {this.secure_link.secure_link && this.renderInfoSecureLink()}
        </div>);
    }
}

export default App;
